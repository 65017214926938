<template>
  <landing-card
    :title="title"
    iconType="el-icon-fa-bars"
    :description="description"
    :nextLink="getNextPath"
    :checkRTL="checkRTL"
  >
    <ul class="desc-list">
      <li :class="checkTextRight" v-for="i in declistLength" :key="i">
        <app-i18n-wl :code="'entities.layoutintro.desclist.' + [i - 1]" :lang="lang">
        </app-i18n-wl>

        <img src="/images/timer.png" alt="timer" v-if="i == 1" />
      </li>
    </ul>
  </landing-card>
</template>

<script>
import { i18nWL } from '@/i18n';
export default {
  name: 'online-exam-intro',
  props: ['id', 'lang'],
  data: () => {
    return {
      declistLength: 0,
    };
  },
  async created() {
    const langFile = await import(`@/i18n/${this.lang}`);
    this.declistLength = langFile.default.entities.layoutintro.desclist.length;
  },
  computed: {
    checkRTL() {
      //burda exam cekilmedigi icin lokal checkRTL kullanilir
      return this.lang === 'ar' ? ' rtl ' : '';
    },
    checkTextRight() {
      //burda exam cekilmedigi icin lokal checkRTL kullanilir
      return this.lang === 'ar' ? ' text-right ' : '';
    },
    getNextPath() {
      return `/audio-check/${this.id}/${this.lang}`;
    },
    description() {
      return i18nWL('entities.layoutintro.description', this.lang).replace(
        '${language}',
        this.getTestLang
      );
    },
    title() {
      return i18nWL('entities.layoutintro.title', this.lang);
    },
    getTestLang() {
      let language = i18nWL('entities.language.enumerators.language.' + this.lang, this.lang);
      return language;
    }
  }
};
</script>

<style lang="stylus" scoped>
img {
  display: flex;
  margin: 1rem auto;
  border-radius: 1.25rem;
}
</style>
